export default function IconError() {
  return (
    <svg
      width='160px'
      height='138px'
      viewBox='0 0 160 138'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Artboard' transform='translate(-148.000000, -112.000000)'>
          <g id='Group' transform='translate(148.000000, 112.000000)'>
            <rect id='Rectangle' x='0' y='0' width='160' height='138' />
            <g
              id='icon'
              transform='translate(20.000000, 20.000000)'
              fill='#51F39B'
              fillRule='nonzero'
            >
              <polygon
                id='Path'
                points='0 0 10.6666667 0 20 9.77777778 29.3333334 0 40 0 40 10.6666667 30.2222222 20 40 29.3333334 40 40 29.3333334 40 20 30.2222222 10.6666667 40 0 40 0 29.3333334 9.77777778 20 0 10.6666667'
              />
            </g>
            <g
              id='icon-copy'
              transform='translate(100.000000, 20.000000)'
              fill='#51F39B'
              fillRule='nonzero'
            >
              <polygon
                id='Path'
                points='0 0 10.6666667 0 20 9.77777778 29.3333334 0 40 0 40 10.6666667 30.2222222 20 40 29.3333334 40 40 29.3333334 40 20 30.2222222 10.6666667 40 0 40 0 29.3333334 9.77777778 20 0 10.6666667'
              />
            </g>
            <path
              d='M26,111.618558 C44,93.5436793 62,84.5062399 80,84.5062399 C98,84.5062399 116,93.5436793 134,111.618558'
              id='Line'
              stroke='#51F39B'
              strokeWidth='17'
              strokeLinejoin='bevel'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
