import React from 'react';
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';
import Button, { ButtonProps } from '@amf/shared/components/button/Button';
import IconError from '@amf/shared/icons/IconError';

interface ErrorProps {
  title: string;
  message: string;
  link: string;
  buttonProps?: ButtonProps;
}

export default function Error({ title, message, link, buttonProps }: ErrorProps) {
  const { t } = useTranslate();
  return (
    <div className='Error'>
      <div className='Error__image'>
        <IconError />
      </div>
      <h1 className='Error__title'>{title}</h1>
      <p className='Error__message'>{message}</p>
      <Link href={link}>
        <a>
          <Button variant='green' {...buttonProps}>
            {t('error.link')}
          </Button>
        </a>
      </Link>
    </div>
  );
}
