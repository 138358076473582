import { useEffect } from 'react';
import { useTranslate } from '@tolgee/react';
import Error from '@amf/shared/components/error/Error';
import Container from '@amf/shared/components/layout/Container';

import { AppLinks } from 'utils/links';
import Meta from 'components/Meta';

export default function NotFoundPage() {
  const { t } = useTranslate();

  useEffect(() => {
    document.body.classList.add('withBlueBackground');
    return () => document.body.classList.remove('withBlueBackground');
  }, []);

  return (
    <div className='NotFoundPage'>
      <Meta title={t('error.notFound.title')} description={t('error.notFound.message')} />
      <Container>
        <Error
          title={t('error.notFound.title')}
          message={t('error.notFound.message')}
          link={AppLinks.homepage}
          buttonProps={{
            variant: 'dark',
          }}
        />
      </Container>
    </div>
  );
}
